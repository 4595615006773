import darkScrollbar from '@mui/material/darkScrollbar'
import { createTheme } from '@mui/material/styles'

export const theme = createTheme({
  typography: {
    fontFamily: `Helvetica Neue, Helvetica, Helvetica, Arial, sans-serif, Monoist, Anton`,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          ...darkScrollbar(),
          margin: 0,
        },
      },
    },
  },
})
